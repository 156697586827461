import * as React from 'react';
import cn from 'classnames';

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  content: string;
  noStyle?: boolean;
}

const HtmlContent = ({ className, content, noStyle, ...otherProps }: Props) =>
  noStyle ? (
    <div
      className={cn(className)}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: content }}
      {...otherProps}
    />
  ) : (
    <div
      className={cn('prose-a:text-blue hover:prose-a:text-blue-900', className)}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: content }}
      {...otherProps}
    />
  );

export default HtmlContent;
